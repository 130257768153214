import Vue from 'vue';
import VueRouter from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';
import pages from './routes/pages';
import settings from './routes/settings';
import forms from './routes/forms';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/:crypto',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        Permission: 'no_permission_required',
      },
    },

    ...pages,
    ...settings,
    ...forms,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  // const isLoggedIn = isUserLoggedIn();

  // if (!canNavigate(to)) {
  //   if (!isLoggedIn) return next({ name: 'dashboard' });
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData();
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  // }

  // if (isLoggedIn) {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   const userPerms = JSON.parse(localStorage.getItem('userPerms'));
  //   if (userData.role === 'supermanager') {
  //     return next();
  //   } else {
  //     if (to.meta.Permission === 'no_permission_required') {
  //       return next();
  //     } else {
  //       if (userPerms.includes(to.meta.Permission) == false) {
  //         next({ path: 'not-authorized' });
  //       } else {
  //         return next();
  //       }
  //     }
  //   }
  // } else {
  return next();
  //}
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
