export default [
  {
    path: '/forms',
    name: 'forms',
    component: () => import('@/views/form/FormDetails.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/forms/form-detail/:formId',
    name: 'form-detail',
    component: () => import('@/views/form/FormDetails.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/forms/form-average/:formId',
    name: 'form-average',
    component: () => import('@/views/form/FormAverage.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
