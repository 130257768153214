import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  stoks: [
    {
      id: 1,
      status: "CLOSED", //CLOSED,DELIVERED,INCOMING,IN-STOCK,LOT-OPEN,SOLD
      bookingDate: "2019-10-08",
      year: "2015",
      make: "SCION",
      model: "FRS",
      odometer: "18,800",
      exterior: "GREY",
      interior: "BLACK",
      vinNumber: "JF1ZNAA14F9709997",
      dealerLot: "",
      tag: "",
      listingPrice: "",
      acv: "24,989.10",
      listingGross: "",
      listingMargin: "",
      floorPrice: "",
      floorGross: "",
      floorMargin: "",
      age: "55",
    },
    {
      id: 2,
      status: "CLOSED", //CLOSED,DELIVERED,INCOMING,IN-STOCK,LOT-OPEN,SOLD
      bookingDate: "2019-10-08",
      year: "2019",
      make: "MERCEDES-BENZ",
      model: "A250",
      odometer: "500",
      exterior: "POLAR WHITE",
      interior: "BLACK",
      vinNumber: "WDD3F4HB0KJ049047",
      dealerLot: "",
      tag: "",
      listingPrice: "",
      acv: "33,200.00",
      listingGross: "",
      listingMargin: "",
      floorPrice: "",
      floorGross: "",
      floorMargin: "",
      age: "59",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet("inventory/stoks").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", perPage = 10, page = 1, sortBy = "id", sortDesc = false, role = null, type = null } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.stoks;
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost("inventory/stoks").reply((config) => {
  // Get event from post data

  const { stok } = JSON.parse(config.data);
  stok.id = 0;
  stok.status = "IN-STOCK";
  const { length } = data.stoks;

  let lastIndex = 0;
  if (length) {
    lastIndex = data.stoks[length - 1].id;
  }
  stok.id = lastIndex + 1;

  data.stoks.push(stok);

  return [201, { stok }];
});

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/stoks\/\d+/).reply((config) => {
  // Get event id from URL

  let userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.stoks.findIndex((e) => e.id === userId);
  const user = data.stoks[userIndex];

  if (user) return [200, user];
  return [404];
});

// ------------------------------------------------
// PUT: Update User
// ------------------------------------------------
mock.onPut(/\/stoks\/\d+/).reply((config) => {
  // PUT event from post data

  const { stok } = JSON.parse(config.data);

  let userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.stoks.findIndex((e) => e.id === userId);
  data.stoks[userIndex] = stok;
  const user = data.stoks[userIndex];

  if (user) return [200, user];
  return [404];
});
