import { $themeBreakpoints } from '@themeConfig';
import axiosIns from '@/libs/axios';
import router from '@/router';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  namespaced: true,

  state: {
    //baseURL: "http://127.0.0.1:8000/images/",
    // baseURL: 'https://api.mrorhan.com/storage/app/public/images/',
    // profileURL: 'https://api.mrorhan.com/storage/app/public/profile/',

    // baseURL: 'https://api.regenwald.ca/storage/images/',
    // profileURL: 'https://api.regenwald.ca/storage/profile/',
    // fileURL: 'https://api.regenwald.ca/storage/files/',
    // settingURL: 'https://api.regenwald.ca/storage/settings/',

    baseURL: 'https://api.cis.tailormadesoftwaresolutions.com/storage/app/public/images/',
    profileURL: 'https://api.cis.tailormadesoftwaresolutions.com/storage/app/public/profile/',
    fileURL: 'https://api.cis.tailormadesoftwaresolutions.com/storage/app/public/files/',
    settingURL: 'https://api.cis.tailormadesoftwaresolutions.com/storage/app/public/settings/',

    // baseURL: 'http://127.0.0.1:8000/storage/images/',
    // profileURL: 'http://127.0.0.1:8000/storage/profile/',
    // fileURL: 'http://127.0.0.1:8000/storage/files/',
    // settingURL: 'http://127.0.0.1:8000/storage/settings/',

    windowWidth: 0,
    shallShowOverlay: false,
    token: '',
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },

    isAuthenticated(state) {
      return state.token !== '';
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },

    /*  SET_TOKEN(state, token) {
          state.token = token;
        },
        CLEAR_TOKEN(state) {
          state.token = "";
        },*/
  },
  actions: {
    /* INIT_AUTH({ commit, dispatch }) {
          let token = localStorage.getItem("token");

          if (token) {
            let expirationDate = localStorage.getItem("expirationDate");
            let nowTime = new Date().getTime();

            if (nowTime >= +expirationDate) {
              store.commit("app/CLEAR_TOKEN");
              localStorage.removeItem("token");
              localStorage.removeItem("expirationDate");

              router.replace("/login");
            } else {
              commit("SET_TOKEN", token);
              let timerSecond = +expirationDate - nowTime;

              store.dispatch("app/setTimeOutTimer", timerSecond);
              //  router.push({ name: "/" });
            }
          } else {
            router.push({ name: "login" });
            return false;
          }
        },*/
    /*  setTimeOutTimer({ dispatch }, expiresIn) {
          setTimeout(() => {
            store.commit("app/CLEAR_TOKEN");
            localStorage.removeItem("token");
            localStorage.removeItem("expirationDate");
            router.push({ name: "login" });
            //  router.push({ path: "login" });
          }, expiresIn);
        },*/
  },
};
