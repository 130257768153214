export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      resource: 'settings',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/userrole',
    name: 'settings-userrole',
    component: () => import('@/views/settings/Permissions/role-list/RoleList.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/general',
    name: 'settings-general',
    component: () => import('@/views/settings/General/General.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
];
