import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  customers: [
    {
      id: 1,
      rank: "K-50",
      accountType: "INDEPENDENT DEALER",
      firstName: "0908991 BC LTD",
      lastName: "",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 2,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "ABBOTSFORD VOLKSWAGEN",
      lastName: "",
      phoneNumber: "6048531181",
      email: "",
      address: "30150 AUTOMALL DRIVE ",
      town: "ABBOTSFORD",
      state: "B.C.",
      postZip: "V2T 5M1",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 3,
      rank: "V-25",
      accountType: "INDIVIDUAL",
      firstName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      lastName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 4,
      rank: "S-100",
      accountType: "LEASE COMPANY",
      firstName: "SOLUTION FINANCIAL INC.",
      lastName: "SOLUTION FINANCIAL(CANADA) INC",
      phoneNumber: "6042331937",
      email: "",
      address: "137-8680 Cambie Rd",
      town: "RICHMOND",
      state: "B.C.",
      postZip: "V6X 4K1",
      dealgerRegistration: "30282",
      gstNumber: "877-797-209",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 5,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "BMW VILLE DE QUEBEC",
      lastName: "GROUP AUTTOMOTIVE HOLAN QUEBEC INC.",
      phoneNumber: "418-681-5000",
      email: "",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "",
      state: "UEBEC",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 6,
      rank: "S-100",
      accountType: "FRANCHISEE DEALER",
      firstName: "MERCEDES-BENZ BURLINGTON",
      lastName: "QUANTUM AUTOMOTIVE MBB INCORPORATED",
      phoneNumber: "905-632-4222",
      email: "jay.chamberlain@mbburlington.ca",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "BURLINGTON",
      state: "O.N",
      postZip: "L7P 0A3",
      dealgerRegistration: "5000252",
      gstNumber: "840942163",
      pstNumber: "",
      dealerContact: "JAY CHAMBERLAIN",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 7,
      rank: "K-50",
      accountType: "INDEPENDENT DEALER",
      firstName: "0908991 BC LTD",
      lastName: "",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 8,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "ABBOTSFORD VOLKSWAGEN",
      lastName: "",
      phoneNumber: "6048531181",
      email: "",
      address: "30150 AUTOMALL DRIVE ",
      town: "ABBOTSFORD",
      state: "B.C.",
      postZip: "V2T 5M1",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 9,
      rank: "V-25",
      accountType: "INDIVIDUAL",
      firstName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      lastName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 10,
      rank: "S-100",
      accountType: "LEASE COMPANY",
      firstName: "SOLUTION FINANCIAL INC.",
      lastName: "SOLUTION FINANCIAL(CANADA) INC",
      phoneNumber: "6042331937",
      email: "",
      address: "137-8680 Cambie Rd",
      town: "RICHMOND",
      state: "B.C.",
      postZip: "V6X 4K1",
      dealgerRegistration: "30282",
      gstNumber: "877-797-209",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 11,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "BMW VILLE DE QUEBEC",
      lastName: "GROUP AUTTOMOTIVE HOLAN QUEBEC INC.",
      phoneNumber: "418-681-5000",
      email: "",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "",
      state: "UEBEC",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 12,
      rank: "S-100",
      accountType: "FRANCHISEE DEALER",
      firstName: "MERCEDES-BENZ BURLINGTON",
      lastName: "QUANTUM AUTOMOTIVE MBB INCORPORATED",
      phoneNumber: "905-632-4222",
      email: "jay.chamberlain@mbburlington.ca",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "BURLINGTON",
      state: "O.N",
      postZip: "L7P 0A3",
      dealgerRegistration: "5000252",
      gstNumber: "840942163",
      pstNumber: "",
      dealerContact: "JAY CHAMBERLAIN",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 13,
      rank: "K-50",
      accountType: "INDEPENDENT DEALER",
      firstName: "0908991 BC LTD",
      lastName: "",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 14,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "ABBOTSFORD VOLKSWAGEN",
      lastName: "",
      phoneNumber: "6048531181",
      email: "",
      address: "30150 AUTOMALL DRIVE ",
      town: "ABBOTSFORD",
      state: "B.C.",
      postZip: "V2T 5M1",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 15,
      rank: "V-25",
      accountType: "INDIVIDUAL",
      firstName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      lastName: "BAN DAO OAKRIDGE SEAFOOD RESTAURANT INC.",
      phoneNumber: "604-370-0189",
      email: "JYI530@HOTMAIL.COM",
      address: "8060 CAPSTAN WAY",
      town: "",
      state: "",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 16,
      rank: "S-100",
      accountType: "LEASE COMPANY",
      firstName: "SOLUTION FINANCIAL INC.",
      lastName: "SOLUTION FINANCIAL(CANADA) INC",
      phoneNumber: "6042331937",
      email: "",
      address: "137-8680 Cambie Rd",
      town: "RICHMOND",
      state: "B.C.",
      postZip: "V6X 4K1",
      dealgerRegistration: "30282",
      gstNumber: "877-797-209",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 17,
      rank: "C-00",
      accountType: "FRANCHISEE DEALER",
      firstName: "BMW VILLE DE QUEBEC",
      lastName: "GROUP AUTTOMOTIVE HOLAN QUEBEC INC.",
      phoneNumber: "418-681-5000",
      email: "",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "",
      state: "UEBEC",
      postZip: "",
      dealgerRegistration: "",
      gstNumber: "",
      pstNumber: "",
      dealerContact: "",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
    {
      id: 18,
      rank: "S-100",
      accountType: "FRANCHISEE DEALER",
      firstName: "MERCEDES-BENZ BURLINGTON",
      lastName: "QUANTUM AUTOMOTIVE MBB INCORPORATED",
      phoneNumber: "905-632-4222",
      email: "jay.chamberlain@mbburlington.ca",
      address: "215 RUE ETHIENNE DUBREUIL",
      town: "BURLINGTON",
      state: "O.N",
      postZip: "L7P 0A3",
      dealgerRegistration: "5000252",
      gstNumber: "840942163",
      pstNumber: "",
      dealerContact: "JAY CHAMBERLAIN",
      tran: "",
      tradeInBalance: "",
      lienBalanceOnTrade: "",
      customerEquityBalence: "",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet("users").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", perPage = 10, page = 1, sortBy = "id", sortDesc = false, account = null, rank = null } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.customers.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.firstName.toLowerCase().includes(queryLowered) || user.lastName.toLowerCase().includes(queryLowered)) &&
      user.rank === (rank || user.rank) &&
      user.accountType === (account || user.accountType) // &&
    //  user.status === (status || user.status)
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost("customer/customers").reply((config) => {
  // Get event from post data

  const { customer } = JSON.parse(config.data);
  customer.id = 0;
  customer.rank = "C-00";
  const { length } = data.customers;

  let lastIndex = 0;
  if (length) {
    lastIndex = data.customers[length - 1].id;
  }
  customer.id = lastIndex + 1;

  data.customers.push(customer);

  return [201, { customer }];
});

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/customers\/\d+/).reply((config) => {
  // Get event id from URL

  let userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.customers.findIndex((e) => e.id === userId);
  const user = data.customers[userIndex];

  if (user) return [200, user];
  return [404];
});

// ------------------------------------------------
// PUT: Update User
// ------------------------------------------------
mock.onPut(/\/customers\/\d+/).reply((config) => {
  // PUT event from post data

  const { customer } = JSON.parse(config.data);

  let userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.customers.findIndex((e) => e.id === userId);
  data.customers[userIndex] = customer;
  const user = data.customers[userIndex];

  if (user) return [200, user];
  return [404];
});
